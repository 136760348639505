@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GoogleFonts */


/* Variables */
:root {
  --header-height: 3rem;

  --first-hue: 250;
  --sat:66%;
  --lig:75%;
  --second-hue:219;

  --first-color: hsl(var(--first-hue), var(--sat), var(--lig));
  --first-color-alt: hsl(var(--first-hue), var(--sat), 71%);
  --title-color: hsl(var(--second-hue), 15%, 95%);
  --text-color: hsl(var(--second-hue), 8%, 75%);
  --text-color-light: hsl(var(--second-hue), 4%, 55%);
  --body-color: hsl(var(--second-hue), 48%, 8%);
  --container-color: hsl(var(--second-hue), 32%, 12%);
  --background-title-color: hsl(var(--second-hue), 4%, 14%);

  --input-color: hsl(var(--hue-color), 70%, 96%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

  --body-font: 'Poppins', sans-serif;
  --biggest-font-size: 1.75rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;
  --tiny-font-size: .625rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --mb-0-25: .25rem;
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}
@media screen and (min-width: 968px) {
  :root {
      --big-font-size: 2.5rem;
      --h1-font-size: 2.25rem;
      --h2-font-size: 1.5rem;
      --h3-font-size: 1.25rem;
      --normal-font-size: 1rem;
      --small-font-size: .875rem;
      --smaller-font-size: .813rem;
  }
}

/* Default CSS */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  width: 99%;
  height: 100%;
}

body, 
button,
input,
textarea{
  font-family: 'Poppins', sans-serif;
  font-family: var(--body-font);
  font-size: .938rem;
  font-size: var(--normal-font-size);
}

body {
  margin: 0 0 3rem 0;
  margin: 0 0 var(--header-height) 0;
  font-size: .938rem;
  font-size: var(--normal-font-size);
  color: hsl(219, 8%, 75%);
  color: var(--text-color);
  width: 100vw;
}

h1, h2, h3 {
  color: hsl(219, 15%, 95%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a:link {
  text-decoration: none;
}

button{
  cursor:pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

.main{
  background-color: hsl(219, 48%, 8%);
  background-color: var(--body-color);
  margin:0;
  width: 100vw;
}

.section__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
  color: hsl(219, 15%, 95%);
  color: var(--title-color);
  margin-bottom: 4rem;
}
@media (max-width: 767px) {
  .section__title {
    margin-bottom: 2rem;
  }
}

.section__title, 
.section__subtitle {
  text-align: center;
  z-index: 10 !important;
}

.section__subtitle{
  display: block;
  font-size: .813rem;
  font-size: var(--small-font-size);
  color:hsl(219, 4%, 55%);
  color:var(--text-color-light)
}

.container{
  width:  968px !important;
  display: flex;
  max-width: 100%;
}
.background__title{
  position: relative;
  z-index: 0 !important;
  color:hsl(219, 4%, 14%);
  color:var(--background-title-color);
  font-size: 8rem;
  width: 90%;
  padding: 0.2rem;
  -webkit-transform: translateY(1.6rem) !important;
          transform: translateY(1.6rem) !important;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .background__title{
    font-size: 3rem;
    -webkit-transform: translateY(1rem) !important;
            transform: translateY(1rem) !important;
  }
}

.header-container{
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow: 0 4px 4 px hsla(0,0%, 4%, .3);
}

.grid{
  display: grid;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.main{
  overflow: hidden;
}

.section{
  padding:0.5rem 0 1rem;
}

/* THEME CHANGE */
.change-theme{
  transition: .4s;
  font-size: 1.5rem !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.4rem !important; 
  cursor: pointer;
}
.change-theme:hover{
  color: hsl(250, 66%, 75%);
  color: var(--first-color);
}
.spaceBreak{
  margin: 6rem;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .spaceBreak{
    margin: 1rem;
  }
}


/************ NAVBAR ************/
@media (max-width: 950px) {
  .nav__header__menu{
    visibility: hidden;
  }
}
@media (min-width: 950px) {
  .nav__menu{
    visibility: hidden;
  }
}
.header{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  background-color: hsl(219, 48%, 8%);
  background-color: var(--body-color);
  z-index: 100;
  z-index: var(--z-fixed);
  box-shadow: 0 4px 4 px hsla(0,0%, 4%, .3);
}

.active{
  background: linear-gradient(180deg,
              hsla(250, 66%, 75%, 1),
              hsla(250, 66%, 75%, .2));
  background: linear-gradient(180deg,
              hsla(var(--first-hue), var(--sat), var(--lig), 1),
              hsla(var(--first-hue), var(--sat), var(--lig), .2));
  box-shadow: 0 0 16px hsla(250, 66%, 75%, .4);
  box-shadow: 0 0 16px hsla(var(--first-hue), var(--sat), var(--lig), .4);
}
.active-top{
    text-decoration: none !important;
    background-image: linear-gradient(#907ee3a0, #907ee3a0) !important;
    background-size: 0% 0.01em !important;
    background-repeat: no-repeat !important;
    padding-bottom: 0.3rem !important;
    transition: background-size 0.3s ease-in-out !important;
    color:#f2f5f7cf !important;
    background-size: 100% 0.1em !important;
}

.nav{
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo{
  color:hsl(250, 66%, 75%);
  color:var(--first-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  transition: .4s;
  font-size: 2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.nav__logo:hover{
  color:hsl(250, 66%, 71%);
  color:var(--first-color-alt);
}

.nav__menu{
  position: absolute;
  bottom: 1rem;
}


.nav__subMenu{
  position: fixed;
  max-width: 60%;
  bottom: 1rem;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: hsla(219, 32%, 16%, .8);
  background-color: hsla(var(--second-hue), 32%, 16%, .8);
  width:90%;
  border-radius: 4rem;
  padding: 0.6rem 1.1rem;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.nav__list{
  display: flex;
  margin: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: space-between;
  align-items: center;
}
.nav__header__list{
  display:flex;
  margin:0;
}
.nav__header__item{
  padding-left: 2rem;
  padding-right: 2rem;
  cursor:pointer;
}
.nav__header__link{
    text-decoration: none;
    color:#eceff18d;
    background-image: linear-gradient(#907ee3a0, #907ee3a0);
    background-size: 0% 0.01em;
    background-position-y: 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
    padding-bottom: 0.3rem;
    transition: background-size 0.3s ease-in-out;
}
.nav__header__link:hover{
    color:#917EE3 !important;
    text-decoration: none;
    background-size: 100% 0.1em;
    background-position-x: 0%;
}

@media (max-width: 767px) {
  .nav__list{
    padding-left: 0.2rem !important;
    padding-right: .2rem !important;
  }
  .nav__subMenu{
    max-width: 100%;
  }
}


.nav__link, .moon-button{
  color:hsl(219, 8%, 75%);
  color:var(--text-color);
  display: flex;
  border-radius: 5rem;
  font-size: rem;
  padding:0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor:pointer;
  text-decoration: none;
}

.nav__link:hover, .moon-button:hover{
  color:hsl(250, 66%, 71%);
  color:var(--first-color-alt);
}

/************ BANNER ************/
.background__title__banner{
  -webkit-transform: translateY(3rem) !important;
          transform: translateY(3rem) !important;
}

.home-section {
  margin-left: 3rem;
  margin-right: 3rem;
  max-width: 100vw;
}
.home-content.home__container.container.grid{
  -webkit-transform: translateY(-3rem);
          transform: translateY(-3rem);
}
@media (max-width: 767px) {
  .home-section {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-top: 2rem;
  }
  .banner__section{
    margin-top: 6rem;
  }
  .home-content.home__container.container.grid{
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
  }
}

.home-content {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
  grid-row-gap: 4.5rem;
  row-gap: 4.5rem;
}

.home__greeting,
.home__education{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.home__greeting{
  display: block;
  color: hsl(219, 15%, 95%);
  color: var(--title-color);
  margin-bottom: .25rem;
  max-width:300px
}
.home__education{
  color: hsl(219, 8%, 75%);
  color: var(--text-color);
  margin-bottom: 2.5rem;
}
.home__name{
  font-size: 1.75rem;
  font-size: var(--biggest-font-size);
}

.home-header{
  margin-left: 16%;
  margin-bottom: 2rem;
  text-align: left;
}

.home__handle{
  background: linear-gradient(180deg,
              hsla(250, var(--sal), 75%),
              hsla(250, var(--sal), 75%),
              );
  background: linear-gradient(180deg,
              hsla(var(--first-hue), var(--sal), var(--lig, 1)),
              hsla(var(--first-hue), var(--sal), var(--lig, .2)),
              );
  border-radius: 10rem 10rem 1rem 1rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;  
}
.home__img{
  width: 90%;
  max-height: 400px;
}

.home__social{
  position: fixed;
  margin-left: 0.8rem;
  margin-bottom: 4rem;
  bottom: 5rem;
  left: 0;
  display: grid;
  grid-row-gap: .5rem;
  row-gap: .5rem;
}
.home__social-link{
  width: -webkit-max-content;
  width: max-content;
  background-color: hsl(219, 32%, 12%);
  background-color: var(--container-color);
  color:hsl(250, 66%, 75%);
  color:var(--first-color);
  padding: .25rem;
  border-radius: .25rem;
  display:flex;
  font-size: 1.6rem;
  transition: .4s;
}
.home__social-link:hover{
  background-color: hsl(250, 66%, 75%);
  background-color: var(--first-color);
  color:#fff;
}
.home__social::after{
  content: '';
  width: 50px;
  height: 2px;
  background-color: hsl(250, 66%, 75%);
  background-color: var(--first-color);
  -webkit-transform: rotate(90deg) translate(27px, 8px);
          transform: rotate(90deg) translate(27px, 8px);
}


@media (max-width: 767px) {

  .home__social-link{
    width: -webkit-max-content;
    width: max-content;
    background-color: hsl(219, 32%, 12%);
    background-color: var(--container-color);
    color:hsl(250, 66%, 75%);
    color:var(--first-color);
    padding: .25rem;
    border-radius: .25rem;
    display:flex;
    font-size: 1.2rem;
    transition: .4s;
  }

  .home__social::after{
    content: '';
    width: 50px;
    height: 2px;
    background-color: hsl(250, 66%, 75%);
    background-color: var(--first-color);
    -webkit-transform: rotate(90deg) translate(27px, 11px);
            transform: rotate(90deg) translate(27px, 11px);
  }
}


@media (max-width: 767px) {
  .topBannerDiv{
    display:flex;
  }
}

/* ABOUT */

.about__container{
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
  max-width: 100vw;
}
.aboutBanner{
  display: flex;
  justify-content: center;
  align-items: center;  
  max-width: 100vw;
  margin-bottom: 2rem;
}
  .firstDiv{
    margin-right: 3rem;
    border: 0.5px solid hsl(250, 66%, 75%);
    border: 0.5px solid var(--first-color);
    border-radius: 2%;
  }
    .about__img{
      border-radius: 6%;
      max-height: 650px;
    }
  .secondDiv{
    margin-top: 2rem;
  }
  @media (max-width: 767px) {
    .aboutBanner{
      display: block;
      padding-left: 3rem;
      padding-right: 3rem;
      margin-bottom: 1rem;

    }
      .firstDiv{
        width:60%;
        margin-left: auto;
        margin-right: auto;
      }
        .about__img{
          border-radius: 6%;
          text-align: center;
        }
      .about__description{
        text-align: center;
      }
  }

.about_data{
  max-width: 100vw;
}

.about__info{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap:1rem;
  gap:1rem;
  margin-bottom: 2rem;
  max-width: 100vw;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.about__box{
  justify-content: center;
  text-align: center;
  background-color: hsl(219, 32%, 12%);
  background-color: var(--container-color);
  border-radius: .75rem;
  padding: 1.3rem 2rem;  
  padding-bottom:2rem;
}

@media (max-width: 767px) {

  
  .about__info{
    display: block;
  }
  .about__box{
    justify-content: center;
    text-align: center;
    background-color: hsl(219, 32%, 12%);
    background-color: var(--container-color);
    border-radius: .75rem;
    padding: 1.3rem 2rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

  }
  
}
.about__icon{
  font-size: 1.5rem;
  color:hsl(250, 66%, 75%);
  color:var(--first-color);
  margin-bottom: .5rem;
}

.about__title{
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom:1rem;
}

.about__subtitle{
  font-size: .813rem;
  font-size: var(--small-font-size);
  color: hsl(219, 8%, 75%);
  color: var(--text-color);
}
.about__subtitle{
  font-size: .813rem;
  font-size: var(--small-font-size);
  color: hsl(219, 4%, 55%);
  color: var(--text-color-light);
}
.about__description{
  justify-content: center;
  align-items: center;  
  color: hsl(219, 8%, 75%);  
  color: var(--text-color);
  overflow-wrap: break-word; 
  text-align: justify;
}
.aboutContactBtn{
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 3rem;
}


/* BUTTONS */
.button{
  display: inline-block;
  padding: .75rem 1rem;
  border-radius: .5rem;
  font-weight: 500;
  font-weight: var(--font-medium);
  transition: .4s;
  background-color: transparent;
  border: 2px solid hsl(250, 66%, 75%);
  border: 2px solid var(--first-color);
  color:hsl(250, 66%, 75%);
  color:var(--first-color)
}
.button:hover{
  background-color: hsl(250, 66%, 75%);
  background-color: var(--first-color);
  color:hsl(219, 48%, 8%);
  color:var(--body-color);
}


/* SKILLS */
.skills__container{
  grid-row-gap:2rem;
  row-gap:2rem;
  padding-top: 1rem;
  display: flex;
}

.skills__content{
  background-color: hsl(219, 32%, 12%);
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (max-width: 767px) {
  .skills__container{
    margin:0rem;
    display: block;
  }
  .skills__content{
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top:1rem;
    margin-bottom:1rem;
    padding: 1rem;
  }
}



.skills__title{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  color: hsl(250, 66%, 75%);
  color: var(--first-color);
  text-align: center;
  margin-bottom: 1.5rem;
}

.skills__box{
  display: flex;
  justify-content: center;
  grid-column-gap: 2.5rem;
  -webkit-column-gap: 2.5rem;
          column-gap: 2.5rem;
  padding: 0.5rem;
}

.skills__group{
  display: grid;
  align-content: flex-start;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.skills__data{
  display: flex;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.skills .bxs-badge-check{
  font-size: 1rem;
  color: hsl(250, 66%, 75%);
  color: var(--first-color);
}

.skills__name{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  line-height: 18px;
  white-space: nowrap;
}

.skills__level{
  font-size: .625rem;
  font-size: var(--tiny-font-size);
  color:hsl(219, 4%, 55%);
  color:var(--text-color-light)
}

/* SERVICES */
.services__container{
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
  padding-top: 1rem;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

@media (max-width: 767px) {
  .services__container{
    grid-template-columns: repeat(1, 1fr);
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
    grid-gap: 0.8rem;
    gap: 0.8rem;

  }
}

.services__card{
  background-color: hsl(219, 32%, 12%);
  background-color: var(--container-color);
  padding: 2rem 3rem;
  border-radius: 1rem;
  cursor:pointer;
}
.services__card:hover{
  background-color: hsl(219, 32%, 16%);
  background-color: hsl(var(--second-hue), 32%, 16%);
}

.services__title{
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom: 2.5rem;
}
.services__button{
  color: hsl(250, 66%, 75%);
  color: var(--first-color);
  font-size: .813rem;
  font-size: var(--small-font-size);
  display:flex;
  align-items: center;
  grid-column-gap: .25rem;
  -webkit-column-gap: .25rem;
          column-gap: .25rem;
  cursor:pointer;
}
.services__card:hover .services__icon{
  -webkit-transform: translateX(.25rem);
          transform: translateX(.25rem);
}

.services__icon{
  font-size: 1rem;
  transition: .4s;
}

.services__modal{
  position: fixed;
  inset:0;
  background-color: hsla(219, 28%, 16%, .7);
  background-color: hsla(var(--second-hue), 28%, 16%, .7);
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  visibility: hidden;
  opacity: 0;
  transition: .4s;
  z-index: 1000;
  z-index: var(--z-modal);
}
@media (max-width: 767px) {
  .services__modal{
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
  .services__modal-content{
    padding: 0rem 0rem 0rem;
    max-width: 90%;
    left:0;
  }
  .services__modal-info{
    text-align: center;
  }
}
.services__modal-content{
  position: relative;
  background-color: hsl(219, 48%, 8%);
  background-color: var(--body-color);
  padding: 4.5rem 1.5rem 2.5rem;
  border-radius: 1.5rem;
}

.services__modal-title,
.services__modal-description{
  text-align:center
}
.services__modal-title{
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  color:hsl(250, 66%, 75%);
  color:var(--first-color);
  margin-bottom: 1rem;
}

.services__modal-description{
  font-size: .813rem;
  font-size: var(--small-font-size);
  color:hsl(219, 8%, 75%);
  color:var(--text-color);
  margin-bottom: 2rem;
}
.services__modal-list{
  display: grid;
  grid-row-gap: .75rem;
  row-gap: .75rem;
  color:hsl(219, 8%, 75%);
  color:var(--text-color);
  place-items: center;
  padding-left: 0;
}

.services__modal-item{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: .5rem;
  -webkit-column-gap: .5rem;
          column-gap: .5rem;
}

.services__modal-icon{
  font-size: 1.5rem;
  color:hsl(250, 66%, 75%);
  color:var(--first-color)
}

.services__modal-info{
  font-size: .813rem;
  font-size: var(--small-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
}

.services__modal-close{
  position: absolute;
  top:1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: hsl(250, 66%, 75%);
  color: var(--first-color);
  cursor: pointer;
}

.active-modal{
  opacity:1;
  visibility: visible;
}



/* WORK */
.work__container{
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .work__section{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .work__container{
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0rem;
    margin-right: 0rem;
    grid-gap:0.5rem;
    gap:0.5rem;
    margin:0;
  }
  .work__filters{
    margin-bottom: 1rem !important;
  }
  .work__card{
    padding:0.7rem !important;
  }
}
.work__filters{
  display:flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: .30rem;
  -webkit-column-gap: .30rem;
          column-gap: .30rem;
  margin-bottom: 2rem;
}
.work__items{
  cursor: pointer;
  color: hsl(219, 15%, 95%);
  color: var(--title-color);
  padding: .25rem .75rem;
  font-weight: 500;
  font-weight: var(--font-medium);
  border-radius: .5rem;
}
.work__card{
  background-color: hsl(219, 32%, 12%);
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: 1rem;
}
.work__card:hover{
  background-color: hsl(219, 32%, 16%);
  background-color: hsl(var(--second-hue), 32%, 16%);
}
.work__img{
  border-radius: 1rem;
  margin-bottom: .75rem;
}
.projectCardLow{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.work__header{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.work__title{
  font-size: .938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-bottom: .25rem;
}
.work__git{
  -webkit-filter: invert(97%) sepia(2%) saturate(2287%) hue-rotate(184deg) brightness(76%) contrast(83%);
          filter: invert(97%) sepia(2%) saturate(2287%) hue-rotate(184deg) brightness(76%) contrast(83%);
  cursor:pointer;
}
.work__git:hover{
  -webkit-filter: invert(97%) sepia(2%) saturate(2287%) hue-rotate(184deg) brightness(90%) contrast(83%);
          filter: invert(97%) sepia(2%) saturate(2287%) hue-rotate(184deg) brightness(90%) contrast(83%);
}
.work__description{
  font-size: .813rem;
  font-size: var(--small-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  color:hsl(219, 4%, 55%);
  color:var(--text-color-light)
}
.work__techStack{
  margin-top: 1.2rem;
  display: inline-block;
  align-items: center;
  justify-content: center;
}
.tech__icon{
  -webkit-filter: invert(25%) sepia(7%) saturate(606%) hue-rotate(180deg) brightness(99%) contrast(91%);
          filter: invert(25%) sepia(7%) saturate(606%) hue-rotate(180deg) brightness(99%) contrast(91%);
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  display:inline-block;
}
.bottomProjectCard{
  display:flex;
  justify-content: space-between;
  margin-top: 1rem;
  bottom:0;
  vertical-align:bottom;
}
.work__button{
  width: -webkit-max-content;
  width: max-content;
  color:hsl(250, 66%, 75%);
  color:var(--first-color);
  font-size: .813rem;
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  grid-column-gap: .25rem;
  -webkit-column-gap: .25rem;
          column-gap: .25rem;
}
.work__button:hover .work__icon{
  -webkit-transform: translateX(.25rem);
          transform: translateX(.25rem);
}
.work__time{
  padding:3px;
  padding-left:6px;
  padding-right:6px;

  background-color: hsl(250, 66%, 75%);

  background-color: var(--first-color);
  font-size: .625rem;
  font-size: var(--tiny-font-size);
  font-weight: bold;
  border-radius: 3px;
}

.work__icon{
  font-size: 1rem;
  transition:.4s;
}

.active-work{
  background-color: hsl(250, 66%, 75%);
  background-color: var(--first-color);
  color: hsl(219, 48%, 8%);
  color: var(--body-color);
}


/* CONTACT */
.contact__section{
  padding-bottom: 0;
}
.contact__container{
  grid-row-gap: 3rem;
  row-gap: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 7rem;
}
.contact__title{
  text-align: center;
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom: 2.5rem;
}
.contact__info{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap:1rem;
  gap:1rem;
}
@media (max-width: 767px) {
  .contact__info{
    grid-template-columns: repeat(1, 1fr);
  }
  .contact__card{
    margin-left:3.5rem;
    margin-right:3.5rem;
  }
}

.contact__card{
  background-color: hsl(219, 32%, 12%);
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: .75rem;
  text-align: center;
}
.contact__card-icon{
  font-size: 2rem;
  color:hsl(219, 15%, 95%);
  color:var(--title-color);
  margin-bottom: .25rem;
}
.contact__card-title,
.contact__card-data{
  font-size: .813rem;
  font-size: var(--small-font-size);
}
.contact__card-title{
  font-weight: 500;
  font-weight: var(--font-medium);
}
.contact__card-data{
  display: block;
  margin-bottom: .75rem;
  color: hsl(219, 4%, 55%);
  color: var(--text-color-light);
}
.contact__card-data:hover{
  color: hsl(219, 4%, 55%);
  color: var(--text-color-light);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}
.contact__button{
  color:hsl(250, 66%, 75%);
  color:var(--first-color);
  font-size: .813rem;
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: .25rem;
  -webkit-column-gap: .25rem;
          column-gap: .25rem;
}
.contact__button:hover .contact__button-icon{
  -webkit-transform: translateX(.25rem);
          transform: translateX(.25rem);
}

.contact__button-icon{
  font-size: 1rem;
  transition: .4s;
}

/* FOOTER */

.footer{
  background-color: hsl(250, 66%, 75%);
  background-color: var(--first-color);
}

.footer__container{
  padding: 1rem 0 5rem;
  display: block;
}
.footer__list,
.footer__social{
  padding:0;
}
.footer__title,
.footer__link{
  color:hsl(219, 48%, 8%);
  color:var(--body-color)
}
.footer__link:hover{
  color:hsl(219, 15%, 95%);
  color:var(--title-color);
  transition:.4s;
}

.footer__title{
  text-align: center;
  margin-bottom: 0.1rem;
}
.footer__subtitle{
  text-align: center;
  font-size: 0.9rem;
  color:hsl(219, 48%, 8%);
  color:var(--body-color);
  font-weight: .625rem;
  font-weight: var(--tiny-font-size);
  margin-bottom: 2rem;
}
.footer__list{
  display: flex;
  justify-content: center;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
  margin-bottom: 2rem;
}
.footer__social{
  display: flex;
  justify-content: center;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
          column-gap: 1.25rem;
  transition: .4s;
}
.footer__social-link:hover{
  background-color: hsl(250, 66%, 75%);
  background-color: var(--first-color);
  color:#fff;
  transition: .4s;
}
.footer__social-link{
  background-color: hsl(219, 48%, 8%);
  background-color: var(--body-color);
  color: hsl(250, 66%, 75%);
  color: var(--first-color);
  padding:.25rem;
  border-radius: .25rem;
  font-size: 1rem;
  display: inline-flex;
}

.footer__copy{
  display: block;
  margin-top: 2rem;
  color:hsl(219, 32%, 12%);
  color:var(--container-color);
  text-align: center;
  font-size: .625rem;
  font-size: var(--tiny-font-size);
}

/* SCROLL BAR */
::-webkit-scrollbar{
  width: .6rem;
  border-radius: .5rem;
  background-color: hsl(219,8%, 38%);
  background-color: hsl(var(--second-hue),8%, 38%);
}

::-webkit-scrollbar-thumb{
  background-color: hsl(219,8%, 26%);
  background-color: hsl(var(--second-hue),8%, 26%);
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover{
  background-color: hsl(219,8%, 20%);
  background-color: hsl(var(--second-hue),8%, 20%);
}



/* MORE */

.wave {
  -webkit-animation-name: wave-animation;
          animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  -webkit-animation-duration: 2.1s;
          animation-duration: 2.1s; /* Change to speed up or slow down */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; /* Never stop waving :) */
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@-webkit-keyframes wave-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  30% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  }
  40% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes wave-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  30% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  }
  40% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}




/* BREAKPOINTS */
@media screen and (max-width: 280px){
  .nav__menu{
    width: 100%;
  }
  .nav__list{
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .nav__link{
    padding: 0.4rem;
  }
  .home__social{
    position: fixed;
    margin-left: 0.2rem;
    margin-bottom: 4rem;
    bottom: 4.5rem;
    left: 0;
    display: grid;
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }
  .home__social-link{
    width: -webkit-max-content;
    width: max-content;
    background-color: hsl(219, 32%, 12%);
    background-color: var(--container-color);
    color:hsl(250, 66%, 75%);
    color:var(--first-color);
    padding: .25rem;
    border-radius: .25rem;
    display:flex;
    font-size: 1rem;
    transition: .4s;
  }
  .home__social::after{
    content: '';
    width: 40px;
    height: 1px;
    background-color: hsl(250, 66%, 75%);
    background-color: var(--first-color);
    -webkit-transform: rotate(90deg) translate(27px, 8px);
            transform: rotate(90deg) translate(27px, 8px);
  }
  .header-container{
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100vw;
  }
  .home-header{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .home-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .about__info{
    flex-direction: column;
  }
}
